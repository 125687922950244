
import { Component, Prop } from "vue-property-decorator";
import { MfaChallenge } from "../interfaces";
import OtpInput from "./../OtpInput";
import { BaseForm } from "./BaseForm";
import VueCountdown from "@chenfengyuan/vue-countdown";
import { VBPopover, BPopover } from "bootstrap-vue";
import format from "format-util";
import { dayjs } from "./../../libs/dayjs";

@Component({
	components: {
		OtpInput: OtpInput as any,
		VueCountdown,
		BPopover,
	},
	directives: { "b-popover": VBPopover },
})
export default class ChallengeForm extends BaseForm {
	@Prop({ required: true })
	public readonly challenge!: MfaChallenge;

	@Prop({ default: "Verify Account" })
	public readonly title!: string;

	@Prop({ default: "Verify" })
	public readonly submitText!: string;

	public attempts: { max: number; current: number } | false = false;
	public cancelled = false;
	public debounce = 0;
	public debouncing = false;
	public debounceText = "Resend in";
	public expired = false;
	// public ttl = 0;
	public issuing: false | "push" | "pull" = false;

	// @Prop()
	// public readonly instructions!: string;

	public model = {
		code: "",
	};

	public authenticator(suffix: "otp" | "pin") {
		return this.challenge.authenticator === `urn:cashtoken:idp:mfa_authenticators:${suffix}`;
	}

	public transformCountdown(props: Record<string, any>) {
		Object.entries(props).forEach(([key, value]) => {
			// Adds leading zero
			const digits = value < 10 ? `0${value}` : value;

			props[key] = `${digits}`;
		});

		return props;
	}

	public get ttl() {
		const nbf = dayjs.utc(this.challenge.createdAt).add(this.challenge.ttl, "seconds");
		const now = dayjs.utc();
		this.expired = now.isAfter(nbf);
		if (!this.expired) {
			return nbf.diff(now, "millisecond");
		}
		return 0;
	}

	public get message() {
		let message: string = this.challenge.prompt.message;
		if (!message.endsWith(".")) {
			message = `${message}.`;
		}
		if (this.challenge.prompt.interface === "ussd") {
			return String(message).replace(`Dial ${this.challenge.prompt.value}`, "Dial the <strong>USSD string</strong> displayed below");
		}
		return message;
	}

	public get length() {
		if (this.authenticator("pin")) {
			return 4;
		}
		return Number(this.challenge.info.length ?? 6);
	}

	public get canCancel() {
		return !!this.$listeners.cancel;
	}

	public get canRetry() {
		return !!this.$listeners.retry;
	}

	public async cancel() {
		if (this.cancelled) {
			return;
		}

		if (!this.expired) {
			const response = await this.$api.delete(format("/challenge/%s/cancel", this.challenge.cid), {
				headers: {
					"X-Challenge-Token": this.challenge.token,
				},
			});
			if (!response.ok) {
				if (response.error.code !== "MFA_CHALLENGE_EXPIRED") {
					return this.handleError(response);
				}
			}
		}
		if (this.canCancel) {
			this.$emit("cancel");
		}
		this.cancelled = true;
	}

	public retry() {
		if (this.canRetry) {
			this.$emit("retry");
		}
	}

	async issue(_$event: any, type: "push" | "pull", params: Record<string, any> = {}) {
		if (this.issuing !== false) {
			return;
		}
		this.issuing = type;
		try {
			const response = await this.$api.post(
				format("/challenge/%s/issue", this.challenge.cid),
				{
					params: {
						...params,
						// eslint-disable-next-line @typescript-eslint/camelcase
						preferred_delivery: type,
					},
				},
				{
					headers: {
						"X-Challenge-Token": this.challenge.token,
					},
				},
			);
			if (!response.ok) {
				if (response.error.code === "CHALLENGE_ISSUANCE_DEBOUNCE_VIOLATION") {
					const retry = response.headers.get("Retry-After");
					if (retry) {
						this.debounce = Number(retry);
						this.debouncing = true;
						this.debounceText = "Wait for";
					}
					return;
				}
				return this.handleError(response);
			}
			// this.$root.$emit("bv::hide::popover", `popover-${this.challenge.info.delivery.default}`);
			const issuance = response.data.issuance;
			this.challenge.prompt = issuance.prompt;
			this.challenge.info = issuance.info;
			if (issuance.debounce > 0) {
				const iat = dayjs.utc(issuance.timestamp);
				const nbf = iat.add(issuance.debounce, "seconds");
				const now = dayjs.utc();
				const remaining = nbf.diff(now, "seconds");
				this.debounce = remaining < 0 ? 0 : remaining;
				if (this.debounce > 0) {
					this.debounceText = "Resend in";
					this.debouncing = true;
				}
			}
		} finally {
			this.issuing = false;
		}
	}

	// public get hint() {
	// 	if (this.instructions) {
	// 		return this.instructions;
	// 	}
	// 	return `Please enter verification code sent to ${this.principal} in the box provided below`;
	// }

	public async send() {
		if (this.attempts && this.attempts.current === this.attempts.max) {
			this.$formObserver.setErrors({
				code: "Too many failed attempts. You have reached the maximum verification attempts. Please try again after some time",
			});
			return;
		}
		const response = await this.$api.post(format(this.action, this.challenge.cid), this.model, {
			headers: {
				"X-Challenge-Token": this.challenge.token,
			},
		});
		if (!response.ok) {
			if (response.error && ["MAX_ALLOWED_ATTEMPTS_EXCEEDED", "INVALID_BINDING_CODE"].includes(response.error.code)) {
				if (response.error.info && response.error.info.attempts) {
					this.attempts = response.error.info.attempts;
				}
				this.$formObserver.setErrors({
					code:
						this.attempts && this.attempts.current === this.attempts.max
							? "Too many failed attempts. You have reached the maximum verification attempts. Please try again after some time"
							: response.error.message,
				});
				return;
			}
			if (response.error.code === "MFA_CHALLENGE_EXPIRED") {
				this.expired = true;
				return;
			}
			return this.handleError(response);
		}
		this.$emit("done", response.body);
	}
}
